
# Note that in dev this won't automatically recompile 



window.Fun ||= {}

window.Fun.app = "shiftapp"

window.translations = {"color":"color","html-lang":"en-US","html_lang":"en-US","FUN_APP":"shiftapp","rota":"schedule","rotas":"schedules","Rota":"Schedule","Rotas":"Schedules","Rotaville":"ShiftApp","rotaville":"shiftapp","rotaville_com":"shiftapp.com","Rotaville_com":"ShiftApp.com","ROTAVILLE":"SHIFTAPP","R":"S","Revolutionise":"Revolutionize","Optimise":"Optimize","aged_care":"senior care","rota_planning":"Scheduling"}

window.locale_loaded = "en_SHIFTAPP"
  
window.t = (key) ->
  if translations[key]
    return translations[key]
  else
    error_string = "MISSING_TRANSLATION(#{key})"
    console?.log error_string
    return error_string

